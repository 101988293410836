body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
}

h1,
h2 {
  line-height: 1.4;
  font-weight: 300;
  margin-top: 0;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

h2 {
  font-weight: 500;
  font-size: 1.2rem;
}

a {
  color: #0077c0;
}

.badge {
  background-color: #0077c0;
  color: white;
}

.small {
  font-size: 0.7rem;
}

.navbar-light .navbar-nav .nav-link,
.navbar-nav .nav-link {
  color: #555555;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}

.dropdown-item {
  font-size: 0.9rem;
}

.titleBar {
  background-color: #0077c0;
}
.breadcrumb {
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
}

.breadcrumb-item a {
  color: white;
}

.breadcrumb-item.active {
  color: #82cfff;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: white;
}

.tile {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 2em;
  text-transform: uppercase;
}

.footer,
.footer a {
  font-size: 0.85rem;
  color: #777;
}

.footer .head {
  font-weight: 600;
}

/* EXPLORE */

.explore-list-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  height: 90vh;
}

.explore-list {
  overflow: auto;
  max-height: 94vh;
}

.explore-list-item {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}

.explore-list-item h2 {
  font-size: 18px;
  color: #394c5b;
  font-weight: bold;
  line-height: 24px;
}

.explore-list-item .description {
  flex: 8 1;
}

.explore-list-item .second {
  color: #808492;
  font-weight: 300;
  margin-top: 6px;
  line-height: 16px;
}

.explore-list-item .state {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #808492;
}

.explore-list-item .image {
  flex: 3 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}

.detail-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.explore-image {
  display: flex;
  justify-content: center;
}

.explore-detail .monumentDetails {
  padding: 20px 32px;
}
.explore-detail.leading {
  margin: 10px 0px;
}
.explore-detail .title {
  font-size: 24px;
  line-height: 32px;
  padding: 4px 0px;
}
.explore-detail .description {
  font-weight: 300;
  overflow: auto;
}
.explore-buttons {
  border-top: 1px solid #edeaea;
  border-bottom: 1px solid #edeaea;
  height: 56px;
  display: flex;
  align-items: center;
}
.explore-buttons .navbutton {
  display: flex;
  align-items: center;
  flex-grow: 2;
  height: 100%;
  justify-content: center;
  font-weight: 400;
  cursor: pointer;
  border-right: 1px solid #edeaea;
}

.explore-buttons .navbutton:hover {
  background-color: #addaf7;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

.mapboxgl-map {
  position: relative;
  height: 90vh;
}

@media screen and (max-width: 576px) {
  .explore-image {
    margin-top: 1rem !important;
  }

  .mapboxgl-map {
    height: 30vh;
  }
}

.map-caption {
  background-color: white;
  padding: 8px 12px;
}

.map-caption h2 {
  font-size: 15px;
}

.map-caption span {
  line-height: 16px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #808492;
}

